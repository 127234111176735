
var maps = {
  initialization:true,
  map:null,
  map_el:null,
  zoom: 10,
  lng: null,
  lat: null,
  mode: null,
  center: null,
  start_center: null,

  firstSymbolId: null,

  keep: ["place-city-label-minor", "place-city-label-major","place-town-village-hamlet-label" ],

  markers: [],

  //---------------------------------------------------- init
  init: function(){
    mapboxgl.accessToken = 'pk.eyJ1IjoidGV0ZXNkZXBpb2NoZXMiLCJhIjoiY2tkOHh6OW5vMDM0cDJzbWg2M3FweHp6NSJ9.4GlxlRLz2GWdIszpqjg4pg';
    maps.map_el = document.querySelector('.map');

    if(maps.initialization==false) return;
        
    maps.lng = parseFloat(maps.map_el.getAttribute('data-lng'));
    maps.lat = parseFloat(maps.map_el.getAttribute('data-lat'));
    maps.center = [maps.lng, maps.lat];

    var start_lng = maps.lng + parseFloat((Math.random() * 0.34 * (Math.random() > .5 ? -1 : 1)).toFixed(4));
    var start_lat = maps.lat + parseFloat((Math.random() * 0.34 * (Math.random() > .5 ? -1 : 1)).toFixed(4));    
    
    maps.start_center = [
      start_lng, start_lat
    ]
    
    maps.map = new mapboxgl.Map({
      container: maps.map_el.id, // container id
      style: 'mapbox://styles/tetesdepioches/ckd8xzx4m09r11iqbhzbshd4l', // stylesheet location
      center: maps.start_center, // starting position [lng, lat]
      zoom: 12, // starting zoom
      maxZoom: 18,
      minZoom: 8
    });

    // controls
    // maps.map.scrollZoom.disable();
    maps.map.addControl(new mapboxgl.NavigationControl());
    // maps.map.addControl(new mapboxgl.FullscreenControl());
    // maps.map.addControl(
    //   new mapboxgl.GeolocateControl({
    //       positionOptions: { enableHighAccuracy: true },
    //       trackUserLocation: true
    //   })
    // );

    maps.initialization = false;
    maps.styleMap();
  },
  
  //---------------------------------------------------- styleMap
  styleMap: function(){
    maps.map.on('load', function () {
      // don’t display labels on general map
      maps.map.style.stylesheet.layers.forEach(function(layer) {
        if (layer.type === 'symbol') {
          maps.map.setLayoutProperty(layer.id, 'visibility', 'none');
        }
      });

      var layers = maps.map.getStyle().layers;
      for (var i = 0; i < layers.length; i++) {
        if (layers[i].type === 'symbol') {
          maps.firstSymbolId = layers[i].id;
          break;
        }
      }
      
      // contour / levels lines
      maps.map.addSource('mapbox-terrain', { type: 'vector', url: 'mapbox://mapbox.mapbox-terrain-v2' });      
      maps.map.addLayer({ 'id': 'terrain-data', 'type': 'line', 'source': 'mapbox-terrain', 'source-layer': 'contour', 'layout': {   'line-join': 'round',   'line-cap': 'round' }, 'paint': {   'line-color': '#eee',   'line-width': 1 } }, maps.firstSymbolId);

      // bounds
      var bounds = new mapboxgl.LngLatBounds() ;
 
      maps.map_el.classList.add("styled");
      
      // resize
      window.addEventListener('resize', maps.resizeCallback);
      maps.map.resize();
      
    });
  },

  gotoMap: function(){
    maps.addMarkers();
    maps.map.flyTo({
      center: maps.center,
      zoom: maps.zoom
    })
  },

  //---------------------------------------------------- addMarkers
  addMarkers:function(){
    // 
    maps.map.style.stylesheet.layers.forEach(function(layer) {
      if (layer.type === 'symbol' ) {
        maps.map.setLayoutProperty(layer.id,'visibility', 'visible');
      }
    });     
    
    document.markers.forEach( function(marker) {
      // create a HTML element for each feature
      var el = document.createElement('a');
      var span = document.createElement('span');
      span.innerHTML = "<em>" + marker.title + "</em>";
      el.className = 'marker';
      marker.categories.forEach(function(cat){
        el.classList.add(cat);
      });
      el.classList.add("picto-" + marker.picto);
      el.setAttribute('id', 'marker-' + marker.id);
      el.setAttribute('href', marker.url);    
      el.appendChild(span);   

      // make a marker for each feature and add to the map
      var m = new mapboxgl.Marker(el)
          .setLngLat(marker.latlon)
          .addTo(maps.map);
      
      maps.markers.push(el);
      el.addEventListener('click', function(e){
        e.preventDefault();
        loadActionDetail(this.getAttribute('href'));
      });
    });

    maps.initFilters();
    document.querySelector('.closemap').addEventListener('click', function(e){
      e.preventDefault();
      maps.closeMap();
    });
    document.querySelector('.filter-all').onclick = function(){
      maps.selected_filters = [];
      maps.filters.forEach(function(link){
        maps.selected_filters.push(link.dataset.filter);
        link.classList.add('selected');
      });
      maps.markers.forEach(function(marker){
        marker.classList.remove('hidden');
      });
    };
    document.querySelector('.filter-none').onclick = function(){
      maps.selected_filters = [];
      maps.filtermap();
    };
  },
  
  //---------------------------------------------------- resize
  resizeCallback : function(){ 
    throttle(maps.resizeMap, 100);
  },
  resizeMap:function(){
    if (maps.map !== null) maps.map.resize();  
  },
  
  //---------------------------------------------------- close
  closeMap: function(){ 
    maps.markers.forEach(function(marker){
      marker.classList.add('hidden');
      maps.map.style.stylesheet.layers.forEach(function(layer) {
        if (layer.type === 'symbol') {
          maps.map.setLayoutProperty(layer.id, 'visibility', 'none');
        }
      });
      document.querySelector('.home').classList.remove('hidden');
      document.querySelector('#map-caption').classList.remove('visible');
    })
  },
  
  //---------------------------------------------------- filter
  selected_filters: [],
  filters: document.querySelectorAll('#filters a'),

  filtermap: function(){
    
    // reset filter links
    maps.filters.forEach(function(link){
      link.classList.remove('selected');
    })

    // reset markers
    maps.markers.forEach(function(marker){
      marker.classList.add('hidden');
    })

    // browse filter links
    if(maps.selected_filters.length>0){
      for(var i=0; i<maps.selected_filters.length; i++){
        var filter = maps.selected_filters[i];
        var link = document.querySelector('a[data-filter="' + filter + '"');
        if(link) link.classList.add('selected');
        
        maps.markers.forEach(function(marker){
          if(marker.classList.contains(filter)){
            marker.classList.remove('hidden')
          }
        })
      }
    } 
  },

  initFilters: function(){

    document.querySelector('#map-caption').classList.add('visible');
    // init 
    maps.filters.forEach(function(link){
      maps.selected_filters.push(link.dataset.filter);
      link.classList.add('selected');
    });
    maps.markers.forEach(function(marker){
      marker.classList.remove('hidden');
    });
    maps.filters.forEach(function(link){
      link.addEventListener('click', function(e){
        e.preventDefault();
        if(link.matches('.selected')){
          var index = maps.selected_filters.indexOf(link.dataset.filter);
          if (index > -1) {
            maps.selected_filters.splice(index, 1);
          }
        } else {
          console.log(link.dataset.filter);
          maps.selected_filters.push(link.dataset.filter);
        }
        maps.filtermap();
      })
    })

  }

  
};




//------------------------------------------------------ POI show popup 

function buildActionDetail(content){
  var action = document.querySelector('#action');
  action.classList.remove('visible');
  action.innerHTML = "";

  // close button
  var close = document.createElement('button');
  close.className = "close";
  close.innerHTML = "<span>×</span>";
  action.appendChild(close);

  close.addEventListener('click', function(){
    action.classList.remove('visible');  
    setTimeout(function(){
      history.pushState(null, null, document.trail_url);
      action.removeChild(content);
    }, 250);
  });

  // done loading
  document.querySelector('.map').scrollIntoView({ 
    behavior: 'smooth',
    block: "end",
  });
  action.classList.add('visible');
  action.appendChild(content);
}

// ------------------------------------------------ POI data load

function loadActionDetail(href){

  fetch(href)
  .then(function(response) {
    return response.text();
  })
  .then(function(html) {
    // Initialize the DOM parser
    var parser = new DOMParser();

    // Parse the text
    var doc = parser.parseFromString(html, "text/html");
    var content = doc.querySelector('#action-content');
    console.log(content);
    buildActionDetail(content);
  })

  .catch(function(error) {
    console.log(error);
  });
}


// ------------------------------------------------ INIT


maps.mode = "single";
maps.init();



// ------------------------------------------------ Helpers

// Throttle
// Credit: https://jsfiddle.net/jonathansampson/m7G64
function throttle(callback,limit){
  var wait=false;return function(){
    if(!wait){
      callback.call();
      wait=true;
      setTimeout(function(){wait=false;},limit);}};}

// Hex to HSL
// Credit: https://css-tricks.com/converting-color-spaces-in-javascript/
// function hexToHSL(H){let r=0,g=0,b=0;if(H.length==4){r="0x"+H[1]+H[1];g="0x"+H[2]+H[2];b="0x"+H[3]+H[3]}else if(H.length==7){r="0x"+H[1]+H[2];g="0x"+H[3]+H[4];b="0x"+H[5]+H[6]}r/=255;g/=255;b/=255;let cmin=Math.min(r,g,b),cmax=Math.max(r,g,b),delta=cmax-cmin,h=0,s=0,l=0;if(delta==0){h=0}else if(cmax==r){h=((g-b)/delta)%6}else if(cmax==g){h=(b-r)/delta+2}else{h=(r-g)/delta+4}h=Math.round(h*60);if(h<0){h+=360}l=(cmax+cmin)/2;s=delta==0?0:delta/(1-Math.abs(2*l-1));s= +(s*100).toFixed(1);l= +(l*100).toFixed(1);return "hsl("+h+","+s+"%,"+l+"%)"}

function hexToHSL(H) {
  // Convert hex to RGB first
  var r = 0, g = 0, b = 0;
  if (H.length == 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length == 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  var cmin = Math.min(r,g,b),
      cmax = Math.max(r,g,b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

  if (delta == 0)
    h = 0;
  else if (cmax == r)
    h = ((g - b) / delta) % 6;
  else if (cmax == g)
    h = (b - r) / delta + 2;
  else
    h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0)
    h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return [h, s, l];
  // return "hsl(" + h + "," + s + "%," + l + "%)";
}



// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
function LightenDarkenColor(col,amt) {
  var usePound = false;
  if ( col[0] == "#" ) {
      col = col.slice(1);
      usePound = true;
  }

  var num = parseInt(col,16);

  var r = (num >> 16) + amt;

  if ( r > 255 ) r = 255;
  else if  (r < 0) r = 0;

  var b = ((num >> 8) & 0x00FF) + amt;

  if ( b > 255 ) b = 255;
  else if  (b < 0) b = 0;

  var g = (num & 0x0000FF) + amt;

  if ( g > 255 ) g = 255;
  else if  ( g < 0 ) g = 0;

  return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
}